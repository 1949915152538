import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { getCurrencyRowPropsForFiat } from 'utils/transactions/detail/get-currency-row-props-for-fiat';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { getAchServiceLabel } from 'utils/labels-mapping/get-ach-service-label';
import { REASON_OF_CANCELLATION } from '../constants';

export class TransactionDetailsDepositAchIncomingViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: getCurrencyRowPropsForFiat(transaction, this.assetIconSize),
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionsPage.deposit'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'paymentMethod',
                label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
                uiElementType: RowType.text,
                value: i18n.t('transactionsPage.ach'),
              } as ITextRow,
              {
                id: 'achService',
                label: i18n.t('transactionDetailsPage.labels.achService'),
                uiElementType: RowType.text,
                value: getAchServiceLabel(transaction.achService),
              } as ITextRow,
              {
                id: REASON_OF_CANCELLATION,
                label: i18n.t('transactionDetailsPage.labels.reasonOfCancellation'),
                uiElementType: RowType.text,
                value: i18n.t('transactionDetailsPage.cancellationReason'),
              } as ITextRow,
            ]
              .filter(i => {
                if (i.id === REASON_OF_CANCELLATION) {
                  return transaction.status.toLowerCase() === TransactionStatus.cancelled.toLowerCase();
                }

                return true;
              })
              .filter(i => !!i.value),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'origin',
                label: i18n.t('transactionDetailsPage.labels.origin'),
                uiElementType: RowType.text,
                value: transaction.sourceOrigin ?? i18n.t('transactionDetailsPage.labels.na'),
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: transaction.destinationAccountNumber || '',
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.destinationOwnerIdentityDisplayName,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
        ],
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getCurrencyRowPropsForFiat(transaction, this.assetIconSize).label,
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transaction.status as TransactionStatus),
          } as IPlainRow,

          {
            id: 'transactionId',
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            rowType: PdfRowType.plain,
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            rowType: PdfRowType.plain,
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            label: i18n.t('transactionDetailsPage.labels.date'),
            rowType: PdfRowType.plain,
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            label: i18n.t('transactionDetailsPage.labels.type'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionsPage.deposit'),
            paymentType: transaction.paymentType,
          } as IPlainRow,
          {
            id: 'paymentMethod',
            label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionsPage.ach'),
          } as IPlainRow,
          {
            id: 'achService',
            label: i18n.t('transactionDetailsPage.labels.achService'),
            rowType: PdfRowType.plain,
            value: getAchServiceLabel(transaction.achService),
          } as IPlainRow,
          {
            id: REASON_OF_CANCELLATION,
            label: i18n.t('transactionDetailsPage.labels.reasonOfCancellation'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionDetailsPage.cancellationReason'),
          } as IPlainRow,
        ]
          .filter(i => {
            if (i.id === REASON_OF_CANCELLATION) {
              return transaction.status.toLowerCase() === TransactionStatus.cancelled.toLowerCase();
            }

            return true;
          })
          .filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'origin',
            label: i18n.t('transactionDetailsPage.labels.origin'),
            rowType: PdfRowType.plain,
            value: transaction.sourceOrigin ?? i18n.t('transactionDetailsPage.labels.na'),
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'custodialAccountNumber',
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            rowType: PdfRowType.plain,
            value: transaction.destinationAccountNumber || '',
          } as IPlainRow,
          {
            id: 'accountOwner',
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            rowType: PdfRowType.plain,
            value: transaction.destinationOwnerIdentityDisplayName,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
  }
}
