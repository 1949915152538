import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireRecipientInfoFormData } from 'elements/element-transfer/types';
import {
  resetWithdrawalWireAccountNumbers,
  resetWithdrawalWireBankInfo,
  setFiatCustodyProvider,
  setPage,
  setWithdrawalWireAdditionalDetails,
  setWithdrawalWireAmount,
  setWithdrawalWireRecipientInfo,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import trimAndRemoveExtraSpacesFormData from 'elements/element-transfer/utils/wireWithdrawalUtils/trimAndRemoveExtraSpacesFormData';
import * as api from 'elements/element-transfer/api';
import { setErrorBanner } from 'elements/utils';
import { IntegrationServiceProvider } from 'models/enums/user/integration-service-provider';

const useWireWithdrawalRecipientInfoPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const initialFormData = state[TransferFlow.Withdrawal].WIRE.recipientInfo;
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = async () => {
    const { organization } = await api.transferElement.getUserData();
    dispatch(setFiatCustodyProvider(organization?.fiatCustodyProvider || IntegrationServiceProvider.cos));
  };

  const continueHandler = async (data: WireRecipientInfoFormData) => {
    setIsLoading(true);

    try {
      await getUserData();
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar);
      setIsLoading(false);
      return;
    }

    dispatch(setWithdrawalWireRecipientInfo(trimAndRemoveExtraSpacesFormData(data)));
    if (initialFormData.locationType !== data.locationType) {
      dispatch(resetWithdrawalWireBankInfo());
      dispatch(resetWithdrawalWireAccountNumbers());
      dispatch(setWithdrawalWireAmount(''));
      dispatch(setWithdrawalWireAdditionalDetails({ memo: '', note: '', purposeOfPayment: '' }));
    }
    setIsLoading(false);
    dispatch(setPage(Paths.WithdrawalWireBankInfo));
  };

  const backHandler = () => {
    dispatch(setPage(Paths.WithdrawalWireRecipientSearch));
  };

  return { initialFormData, continueHandler, backHandler, isLoading };
};

export default useWireWithdrawalRecipientInfoPage;
