import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireBankInfoFormData, WireLocationType } from 'elements/element-transfer/types';
import { setPage, setWithdrawalWireBankInfo, useElement } from 'elements/element-transfer/contexts/Element';
import trimAndRemoveExtraSpacesFormData from 'elements/element-transfer/utils/wireWithdrawalUtils/trimAndRemoveExtraSpacesFormData';
import { IntegrationServiceProvider } from 'models/enums/user/integration-service-provider';

const useWireWithdrawalBankInfoPage = () => {
  const { state, dispatch } = useElement();

  const initialFormData = state[TransferFlow.Withdrawal].WIRE.bankInfo;
  const { locationType } = state[TransferFlow.Withdrawal].WIRE.recipientInfo;
  const isDomestic = locationType === WireLocationType.Domestic;
  const { fiatCustodyProvider } = state[TransferFlow.Withdrawal].WIRE;
  const isIntermediaryBankInfoRequired = fiatCustodyProvider === IntegrationServiceProvider.braid_Ssb;

  const saveBankInfoFormData = (data: WireBankInfoFormData) => {
    const { intermediaryBankAbaRoutingNumber, intermediaryBankName, ...bankInfo } = data;
    dispatch(
      setWithdrawalWireBankInfo({
        ...trimAndRemoveExtraSpacesFormData(bankInfo),
        intermediaryBankAbaRoutingNumber: intermediaryBankAbaRoutingNumber?.trim(),
        intermediaryBankName: intermediaryBankName?.trim(),
      }),
    );
  };

  const continueHandler = (data: WireBankInfoFormData) => {
    saveBankInfoFormData(data);
    dispatch(setPage(Paths.WithdrawalWireAccountNumbers));
  };

  const backHandler = (data: WireBankInfoFormData) => {
    saveBankInfoFormData(data);
    dispatch(setPage(Paths.WithdrawalWireRecipientInfo, { withBackButton: true }));
  };

  return { initialFormData, isDomestic, continueHandler, backHandler, isIntermediaryBankInfoRequired };
};

export default useWireWithdrawalBankInfoPage;
