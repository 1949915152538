import { CustodialAccountsRow } from 'ui-interfaces/row-interfaces/custodial-accounts';
import { CustodialAccountResponseModel } from 'models/response/custodial-accounts/custodial-account-response-model';
import { formatDate } from 'utils/format-date';
import i18n from 'localizations';
import { getCustodialAccountTypeLabel } from 'utils/labels-mapping/get-custodial-account-type-label';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { mapCustodialAccountStatusToStatusCellProps } from 'utils/multi-mapping/statuses/map-custodial-account-status-to-status-cell-props';

export class CustodialAccountViewModel implements CustodialAccountsRow {
  readonly id: string;

  readonly custodialAccountId: string;

  readonly custodialAccountNumber: string;

  readonly owner: string;

  readonly typeLabel: string;

  readonly lastActivity?: string;

  readonly statusCellProps: StatusCellProps;

  readonly accountName: string;

  constructor(custodialAccount: CustodialAccountResponseModel) {
    this.id = custodialAccount.id;
    this.custodialAccountId = custodialAccount.id;
    this.custodialAccountNumber = custodialAccount.number;
    this.owner = custodialAccount.ownerIdentityDisplayName;
    this.typeLabel = getCustodialAccountTypeLabel(custodialAccount.type);
    this.lastActivity = custodialAccount.lastActivity && formatDate(custodialAccount.lastActivity);
    this.statusCellProps = mapCustodialAccountStatusToStatusCellProps(
      custodialAccount.status,
      !custodialAccount.isSuspendedByIntegrator,
    );
    this.accountName = custodialAccount.name || i18n.t('custodialAccountsPage.noName');
  }
}
