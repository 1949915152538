import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { getCryptoAmountForActionDialog } from 'utils/transactions/get-crypto-amount-for-action-dialog';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { LabelLength } from 'ui-enums/label-length';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { getCurrencyRowPropsForFiat } from 'utils/transactions/detail/get-currency-row-props-for-fiat';
import { IPdfNoLabelRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-no-label-row';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { getStaticDefaultIconForTransaction } from 'utils/icons-mapping/transactions/get-static-default-icon-for-transaction';

export class TransactionInitialDepositDetailsViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    this.amountForActionDialog = getCryptoAmountForActionDialog(transaction);

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: {
          imageUrl: transaction.assetIconXs,
          size: mapAssetByNetworkIconSizeToNumber(this.assetIconSize),
          defaultAssetIcon: getStaticDefaultIconForTransaction(transaction.assetTicker, this.assetIconSize),

          label: `${getSignOfTransaction(transaction)}${
            transaction.amount || transaction.cryptoAmount
          }  ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`,
          subLabel: '',
        },
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionDetailsPage.labels.initialDeposit'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
            ].filter(i => {
              if (i.uiElementType === RowType.text || i.uiElementType === RowType.textMultiLine) {
                return !!i.value;
              }

              return true;
            }),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: transaction.sourceAccountNumber
              ? [
                  {
                    id: 'custodialAccountNumber',
                    label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                    uiElementType: RowType.text,
                    value: transaction.sourceAccountNumber || '',
                  } as ITextRow,
                  {
                    id: 'accountOwner',
                    label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                    uiElementType: RowType.text,
                    value: transaction.sourceOwnerIdentityDisplayName || '',
                  } as ITextRow,
                ].filter(i => !!i.value)
              : [
                  {
                    id: 'noData',
                    label: '',
                    uiElementType: RowType.valueWithoutLabel,
                    value: i18n.t('transactionDetailsPage.labels.na'),
                  } as ITextRow,
                ],
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: transaction.destinationAccountNumber || '',
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.destinationOwnerIdentityDisplayName,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
        ],
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getCurrencyRowPropsForFiat(transaction, this.assetIconSize).label,
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transaction.status as TransactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            rowType: PdfRowType.plain,
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            rowType: PdfRowType.plain,
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            label: i18n.t('transactionDetailsPage.labels.date'),
            rowType: PdfRowType.plain,
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            label: i18n.t('transactionDetailsPage.labels.type'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionDetailsPage.labels.initialDeposit'),
            paymentType: transaction.paymentType,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: transaction.sourceAccountNumber
          ? [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                rowType: PdfRowType.plain,
                value: transaction.sourceAccountNumber || '',
              } as IPlainRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                rowType: PdfRowType.plain,
                value: transaction.sourceOwnerIdentityDisplayName || '',
              } as IPlainRow,
            ].filter(i => !!i.value)
          : [
              {
                id: 'noData',
                rowType: PdfRowType.noLabelRow,
                value: i18n.t('transactionDetailsPage.labels.na'),
              } as IPdfNoLabelRow,
            ],
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'custodialAccountNumber',
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            rowType: PdfRowType.plain,
            value: transaction.destinationAccountNumber || '',
          } as IPlainRow,
          {
            id: 'accountOwner',
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            rowType: PdfRowType.plain,
            value: transaction.destinationOwnerIdentityDisplayName,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
  }
}
