import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { DestroyActionModal } from 'components/shared/modals/DestroyActionModal';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Column } from 'components/shared/Flex/Column';
import { Gap } from 'components/shared/Gap';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { AccountBeneficiaryRequestModel } from 'models/request/custodial-accounts/account-beneficiary-request-model';
import { NumberField } from 'components/shared/inputs/NumberField';
import { useYupRules } from 'hooks/use-yup-rules';
import { PhoneField } from 'components/shared/inputs/PhoneField';
import { removeDashSymbols } from 'utils/remove-dash-symbols';
import { StyledDialogContent, StyledDialogTitle, StyledForm, StyledIconButton } from '../AddAccountModal/styled';
import {
  StyledLoadingButtonWithSkeleton,
  StyledRemoveButton,
  StyledDialogActions,
  StyledForwardIcon,
  StyledBackIconButton,
  StyledDialog,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  ownerInfo?: AccountBeneficiaryRequestModel;
  onBack: () => void;
  onAddOwner: (owner: AccountBeneficiaryRequestModel) => void;
  onEditOwner: (owner: AccountBeneficiaryRequestModel, email: string) => void;
  onRemoveOwner: (email: string) => void;
};

const BeneficiaryInfoModal: React.FC<Props> = ({
  isOpen,
  onClose,
  ownerInfo,
  onBack,
  onAddOwner,
  onEditOwner,
  onRemoveOwner,
}) => {
  const { t } = useTranslation();
  const { firstNameRule, lastNameRule, emailRuleWithPattern, phoneRule, ssnRule, percentageRule } = useYupRules();
  const isEditFlow = !!ownerInfo;
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const onRemoveModalOpen = () => {
    setIsRemoveModalOpen(true);
  };

  const onRemoveModalClose = () => {
    setIsRemoveModalOpen(false);
  };

  const onRemoveConfirm = () => {
    if (ownerInfo?.email) {
      onRemoveOwner(ownerInfo.email);
    }
    onRemoveModalClose();
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    submitForm,
    validateForm,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: ownerInfo || {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      ssn: '',
      relationship: '',
      percentageOfDivision: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      firstName: firstNameRule,
      lastName: lastNameRule,
      email: emailRuleWithPattern,
      phone: phoneRule,
      ssn: ssnRule,
      percentageOfDivision: percentageRule,
    }),
    onSubmit: (onSubmitValues, { setSubmitting }) => {
      const formattedSubmitValues = {
        ...onSubmitValues,
        percentageOfDivision: +onSubmitValues.percentageOfDivision,
        ssn: removeDashSymbols(onSubmitValues.ssn),
        phone: `+${onSubmitValues.phone}`,
      };

      if (isEditFlow && ownerInfo?.email) {
        onEditOwner(formattedSubmitValues, ownerInfo.email);
      } else onAddOwner(formattedSubmitValues);
      setSubmitting(false);
    },
  });

  const onPhoneNumberChange = (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange({ target: { name: 'phone', value } });
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        values: ownerInfo || {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          ssn: '',
          relationship: '',
          percentageOfDivision: '',
        },
      });
      validateForm();
    }
  }, [isOpen, resetForm, validateForm, ownerInfo]);

  useEffect(() => {
    resetForm({
      values: ownerInfo || {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        ssn: '',
        relationship: '',
        percentageOfDivision: '',
      },
    });
    validateForm();
  }, [ownerInfo, resetForm, validateForm]);

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        <StyledBackIconButton onClick={onBack}>
          <KeyboardBackspaceIcon />
        </StyledBackIconButton>
        <Column>
          <Gap size="_24px" />
          <Typography variant="h4">{t('identityDetailsPage.addAccountModal.beneficiariesTitle')}</Typography>
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column>
          <Gap size="_20px" />
          <StyledForm onSubmit={handleSubmit}>
            <Stack direction="row" gap="20px" justifyContent="space-between">
              <BaseTextField
                name="firstName"
                value={values.firstName}
                label={t('identityDetailsPage.beneficiaryInfoModal.firstName')}
                error={!!errors.firstName && touched.firstName}
                helperText={!!errors.firstName && touched.firstName ? errors.firstName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <BaseTextField
                name="lastName"
                value={values.lastName}
                label={t('identityDetailsPage.beneficiaryInfoModal.lastName')}
                error={!!errors.lastName && touched.lastName}
                helperText={!!errors.lastName && touched.lastName ? errors.lastName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Stack>
            <Gap size="_20px" />
            <BaseTextField
              name="email"
              value={values.email}
              label={t('identityDetailsPage.beneficiaryInfoModal.email')}
              error={!!errors.email && touched.email}
              helperText={!!errors.email && touched.email ? errors.email : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Gap size="_20px" />
            <PhoneField
              name="phone"
              value={values.phone}
              label={t('identityDetailsPage.beneficiaryInfoModal.phone')}
              error={!!errors.phone && touched.phone}
              helperText={!!errors.phone && touched.phone ? errors.phone : ''}
              onChange={onPhoneNumberChange}
              onBlur={handleBlur}
            />
            <Gap size="_20px" />
            <BaseTextField
              name="ssn"
              value={values.ssn}
              label={t('identityDetailsPage.beneficiaryInfoModal.ssn')}
              error={!!errors.ssn && touched.ssn}
              helperText={!!errors.ssn && touched.ssn ? errors.ssn : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Gap size="_20px" />
            <BaseTextField
              name="relationship"
              value={values.relationship}
              label={t('identityDetailsPage.beneficiaryInfoModal.relationship')}
              error={!!errors.relationship && touched.relationship}
              helperText={!!errors.relationship && touched.relationship ? errors.relationship : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Gap size="_20px" />
            <NumberField
              name="percentageOfDivision"
              value={values.percentageOfDivision}
              label={t('identityDetailsPage.beneficiaryInfoModal.percentageOfDivision')}
              error={!!errors.percentageOfDivision && touched.percentageOfDivision}
              helperText={
                !!errors.percentageOfDivision && touched.percentageOfDivision ? errors.percentageOfDivision : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="100"
            />
          </StyledForm>
          <Gap size="_20px" />
          {isEditFlow && (
            <StyledRemoveButton onClick={onRemoveModalOpen} size="large" fullWidth disableRipple>
              <Typography variant="buttonLarge" color="inherit">
                {t('identityDetailsPage.beneficiaryInfoModal.remove')}
              </Typography>
            </StyledRemoveButton>
          )}
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledLoadingButtonWithSkeleton
          endIcon={<StyledForwardIcon />}
          variant="contained"
          size="large"
          text={
            isEditFlow ? (
              <Typography variant="buttonMedium" color="background.paper">
                {t('identityDetailsPage.beneficiaryInfoModal.saveBeneficiary')}
              </Typography>
            ) : (
              <Typography variant="buttonMedium" color="background.paper">
                {t('identityDetailsPage.beneficiaryInfoModal.addBeneficiary')}
              </Typography>
            )
          }
          disabled={!isValid}
          color="primary"
          onClick={submitForm}
          loading={false}
        />
      </StyledDialogActions>
      <DestroyActionModal
        isOpen={isRemoveModalOpen}
        onClose={onRemoveModalClose}
        title={t('actionDialog.title')}
        captionText={t('identityDetailsPage.beneficiaryInfoModal.confirmActionCaption')}
        mainText={`${values.firstName} ${values.lastName}`}
        destroyButtonTitle={t('identityDetailsPage.beneficiaryInfoModal.confirmButtonLabel')}
        destroyButtonLoading={false}
        onDestroyButtonClick={onRemoveConfirm}
      />
    </StyledDialog>
  );
};

export default BeneficiaryInfoModal;
