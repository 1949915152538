import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { formatCurrency } from 'utils/format-currency';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { generateApprovalSection } from 'utils/transactions/generate-approval-section';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { getCurrencyPropsForPreciousMetalBuyDetailsPage } from 'utils/transactions/detail/precious-metal/buy/get-currency-props-for-precious-metal-buy-details-page';
import { getPdfAmountForPreciousMetalBuy } from 'utils/transactions/detail/pdf/get-pdf-amount-for-precious-metal-buy';
import { getCryptoTooltipMessage } from 'utils/transactions/get-crypto-tooltip-message';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';

export class TransactionDetailsPreciousMetalBuyViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    const transactionStatus = transaction.status as TransactionStatus;

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: getCurrencyPropsForPreciousMetalBuyDetailsPage(transaction, this.assetIconSize),
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionDetailsPage.labels.buy'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'price',
                label: i18n.t('transactionDetailsPage.labels.price'),
                uiElementType: RowType.text,
                value: transaction?.price ? `$${formatCurrency(transaction?.price)}` : '',
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? '' : transaction.comment) || '',
              } as ITextRow,
            ].filter(i => {
              if (i.uiElementType === RowType.text || i.uiElementType === RowType.textMultiLine) {
                return !!i.value;
              }

              return true;
            }),
          },
          {
            id: 'accountSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.account'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: transaction.destinationAccountNumber || '',
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.destinationOwnerIdentityDisplayName,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'initiatedBySection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.initiationSectionLabel'),
            blockItems: [
              {
                id: 'initiatedBy',
                label: i18n.t('transactionDetailsPage.labels.initiatedBy'),
                uiElementType: RowType.text,
                value: transaction?.initiatedByDisplayValue || '',
              } as ITextRow,
              {
                id: 'dateInitiated',
                label: i18n.t('transactionDetailsPage.labels.dateInitiated'),
                uiElementType: RowType.text,
                value: transaction.initiatedAt ? `${formatDateAndTimeInUTC(transaction.initiatedAt)} UTC` : '',
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? transaction.comment : '') || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          ...generateApprovalSection(transaction.approvals, transactionStatus),
        ].filter(node => {
          if (node.blockHeaderLabel === i18n.t('transactionDetailsPage.initiationSectionLabel')) {
            return !!transaction.initiatedBy;
          }
          return true;
        }),
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: `${getPdfAmountForPreciousMetalBuy(transaction)}`,
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.date'),
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.type'),
            value: i18n.t('transactionDetailsPage.labels.buy'),
          } as IPlainRow,
          {
            id: 'price',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.price'),
            value: transaction?.price ? `$${formatCurrency(transaction?.price)}` : '',
          } as IPlainRow,
          {
            id: 'comment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.comment'),
            value: transaction.comment || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'accountSection',
        title: i18n.t('transactionDetailsPage.account'),
        items: [
          {
            id: 'custodialAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            value: transaction.destinationAccountNumber || '',
          } as IPlainRow,
          {
            id: 'accountOwner',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            value: transaction.destinationOwnerIdentityDisplayName,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
    this.tooltipMessage = getCryptoTooltipMessage(transactionStatus);
  }
}
