import {
  WireAccountNumbersFormData,
  WireBankInfoFormData,
  WireLocationType,
  WireRecipientInfoFormData,
} from 'elements/element-transfer/types';
import { COUNTRY_CODE_US, DEFAULT_PAGE_COUNT, FIRST_PAGE } from 'elements/constants';
import {
  InternalTransferState,
  RecipientsState,
  WireWithdrawalState,
} from 'elements/element-transfer/contexts/Element/types';
import { ExternalAccount, RecipientCustodialAccount } from 'elements/types';
import { IntegrationServiceProvider } from 'models/enums/user/integration-service-provider';

export const withdrawalWireRecipientInfoInitialState: WireRecipientInfoFormData = {
  city: '',
  country: '',
  fullName: '',
  locationType: WireLocationType.Domestic,
  postalCode: '',
  state: '',
  street1: '',
  street2: '',
};

export const withdrawalWireBankInfoInitialState: WireBankInfoFormData = {
  bankName: '',
  city: '',
  country: COUNTRY_CODE_US,
  postalCode: '',
  state: '',
  street1: '',
  street2: '',
};

export const withdrawalWireAccountNumbersInitialState: WireAccountNumbersFormData = {
  swiftCode: '',
  accountNumber: '',
  confirmAccountNumber: '',
  routingNumber: '',
};

export const recipientsInitialState: RecipientsState<ExternalAccount> = {
  list: [],
  page: FIRST_PAGE,
  pageCount: DEFAULT_PAGE_COUNT,
};

export const withdrawalWireInitialState: WireWithdrawalState = {
  recipientId: '',
  amount: '',
  recipientInfo: withdrawalWireRecipientInfoInitialState,
  bankInfo: withdrawalWireBankInfoInitialState,
  accountNumbers: withdrawalWireAccountNumbersInitialState,
  routingNumberCustomError: null,
  note: '',
  memo: '',
  purposeOfPayment: '',
  isCloseConfirmationRequired: false,
  search: '',
  recipients: recipientsInitialState,
  fiatCustodyProvider: IntegrationServiceProvider.cos,
};

export const internalTransferRecipientsInitialState: RecipientsState<RecipientCustodialAccount> = {
  list: [],
  page: FIRST_PAGE,
  pageCount: DEFAULT_PAGE_COUNT,
};

export const internalTransferInitialState: InternalTransferState = {
  recipientId: '',
  amount: '',
  recipientInfo: {
    name: '',
    accountNumber: '',
  },
  comment: '',
  search: '',
  recipients: internalTransferRecipientsInitialState,
  coinList: [],
  selectedCoin: null,
};
