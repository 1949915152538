import React, { useState } from 'react';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireBankInfoPage';
import useWireWithdrawalBankInfoPage from 'elements/element-transfer/hooks/useWireWithdrawalBankInfoPage';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';

const WireBankInfoPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { initialFormData, isDomestic, continueHandler, backHandler, isIntermediaryBankInfoRequired } =
    useWireWithdrawalBankInfoPage();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  return (
    <>
      <ContentBlock
        initialFormData={initialFormData}
        isDomestic={isDomestic}
        isIntermediaryBankInfoRequired={isIntermediaryBankInfoRequired}
        onContinue={continueHandler}
        onClose={onCustomClose}
        onBack={backHandler}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default WireBankInfoPage;
