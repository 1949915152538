export enum IntegrationServiceProvider {
  treasuryPrime = 'treasuryPrime',
  mx = 'mx',
  checkout = 'checkout',
  plainWireInstructions = 'plainWireInstructions',
  fireblocks = 'fireblocks',
  nmi = 'nmi',
  non = 'non',
  cos = 'cos',
  plaid = 'plaid',
  plaidProcessorToken = 'plaidProcessorToken',
  plainAchInstructions = 'plainAchInstructions',
  flowRpc = 'flowRpc',
  nonApiBank = 'nonApiBank',
  treasuryPrimeWithBankCogent = 'treasuryPrimeWithBankCogent',
  braid_Ssb = 'braid_Ssb',
}
